// https://tech.yandex.com/maps/doc/jsapi/2.1/quick-start/index-docpage/

/* global ymaps: true */
/* global L: true */

L.Yandex = L.Layer.extend({
  options: {
    type: 'yandex#map', // 'map', 'satellite', 'hybrid', 'map~vector' | 'overlay', 'skeleton'
    mapOptions: {
      // https://tech.yandex.com/maps/doc/jsapi/2.1/ref/reference/Map-docpage/#Map__param-options
      yandexMapDisablePoiInteractivity: true,
      balloonAutoPan: false,
      suppressMapOpenBlock: true,
    },
    overlayOpacity: 0.8,
    minZoom: 0,
    maxZoom: 19,
  },

  initialize(type, options) {
    if (typeof type === 'object') {
      options = type
      type = false
    }
    options = L.Util.setOptions(this, options)
    if (type)
      options.type = type

    this._isOverlay = options.type.includes('overlay') || options.type.includes('skeleton')
    this._animatedElements = []
  },

  _setStyle(el, style) {
    for (const prop in style)
      el.style[prop] = style[prop]
  },

  _initContainer(parentEl) {
    const zIndexClass = this._isOverlay ? 'leaflet-overlay-pane' : 'leaflet-tile-pane'
    const _container = L.DomUtil.create('div', `leaflet-yandex-container leaflet-pane ${zIndexClass}`)
    const opacity = this.options.opacity || (this._isOverlay && this.options.overlayOpacity)
    if (opacity)
      L.DomUtil.setOpacity(_container, opacity)

    const auto = { width: '100%', height: '100%' }
    this._setStyle(parentEl, auto) // need to set this explicitly,
    this._setStyle(_container, auto) // otherwise ymaps fails to follow container size changes
    return _container
  },

  onAdd(map) {
    const mapPane = map.getPane('mapPane')
    if (!this._container) {
      this._container = this._initContainer(mapPane)
      map.once('unload', this._destroy, this)
      this._initApi()
    }
    mapPane.appendChild(this._container)
    if (!this._yandex)
      return

    this._setEvents(map)
    this._update()
  },

  beforeAdd(map) {
    map._addZoomLimit(this)
  },

  onRemove(map) {
    map._removeZoomLimit(this)
  },

  _destroy(e) {
    if (!this._map || this._map === e.target) {
      if (this._yandex) {
        this._yandex.destroy()
        delete this._yandex
      }
      delete this._container
    }
  },

  _setEvents(map) {
    const events = {
      move: this._update,
      resize() {
        this._yandex.container.fitToViewport()
      },
    }
    if (this._zoomAnimated) {
      events.zoomanim = this._animateZoom
      events.zoomend = this._animateZoomEnd
    }
    map.on(events, this)
    this.once(
      'remove',
      function () {
        map.off(events, this)
        this._container.remove() // we do not call this until api is initialized (ymaps API expects DOM element)
      },
      this,
    )
  },

  _update() {
    const map = this._map
    const center = map.getCenter()
    this._yandex.setCenter([center.lat, center.lng], map.getZoom())
    const offset = L.point(0, 0).subtract(L.DomUtil.getPosition(map.getPane('mapPane')))
    L.DomUtil.setPosition(this._container, offset) // move to visible part of pane
  },

  _resyncView() {
    // for use in addons
    if (!this._map)
      return

    const ymap = this._yandex
    this._map.setView(ymap.getCenter(), ymap.getZoom(), { animate: false })
  },

  _animateZoom(e) {
    const map = this._map
    const viewHalf = map.getSize()._divideBy(2)
    const topLeft = map.project(e.center, e.zoom)._subtract(viewHalf)._round()
    const offset = map.project(map.getBounds().getNorthWest(), e.zoom)._subtract(topLeft)
    const scale = map.getZoomScale(e.zoom)
    this._animatedElements.length = 0
    this._yandex.panes._array.forEach(function (el) {
      if (el.pane instanceof ymaps.pane.MovablePane) {
        const element = el.pane.getElement()
        L.DomUtil.addClass(element, 'leaflet-zoom-animated')
        L.DomUtil.setTransform(element, offset, scale)
        this._animatedElements.push(element)
      }
    }, this)
  },

  _animateZoomEnd() {
    this._animatedElements.forEach((el) => {
      L.DomUtil.setTransform(el, 0, 1)
    })
    this._animatedElements.length = 0
  },

  _initApi() {
    // to be extended in addons
    ymaps.ready(this._initMapObject, this)
  },

  _mapType() {
    const shortType = this.options.type
    if (!shortType || shortType.includes('#'))
      return shortType

    return `yandex#${shortType}`
  },

  _initMapObject() {
    ymaps.mapType.storage.add('yandex#overlay', new ymaps.MapType('overlay', []))
    ymaps.mapType.storage.add('yandex#skeleton', new ymaps.MapType('skeleton', ['yandex#skeleton']))
    ymaps.mapType.storage.add('yandex#map~vector', new ymaps.MapType('map~vector', ['yandex#map~vector']))
    const ymap = new ymaps.Map(
      this._container,
      {
        center: [0, 0],
        zoom: 0,
        behaviors: [],
        controls: [],
        type: this._mapType(),
      },
      this.options.mapOptions,
    )

    if (this._isOverlay)
      ymap.container.getElement().style.background = 'transparent'

    this._container.remove()
    this._yandex = ymap
    if (this._map)
      this.onAdd(this._map)

    this.fire('load')
  },
})

L.yandex = function (type, options) {
  return new L.Yandex(type, options)
}
